<template>
  <v-card>
    <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
      <v-app-bar flat dark color="primary">
        <v-toolbar-title v-if="operation === 'create'">Nouvelle période</v-toolbar-title>
        <v-toolbar-title v-if="operation === 'update'">Mettre à jour la période</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-card-text>
        <v-form ref="periodeForm">
          <v-menu v-model="date_start_dialog" :close-on-content-click="false" max-width="100%">
            <template v-slot:activator="{on, attrs}">
              <v-text-field v-on="on" v-bind="attrs" :rules="[notNullNorEmpty]"
                            label="Date de début"
                            :value="getFormattedDate(periodeModel.date_start)"></v-text-field>
            </template>
            <v-card>
              <v-date-picker v-model="periodeModel.date_start"></v-date-picker>
              <v-card-actions>
                <v-btn text color="primary" @click="date_start_dialog = false">Fermer</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu v-model="date_end_dialog" :close-on-content-click="false" max-width="100%">
            <template v-slot:activator="{on, attrs}">
              <v-text-field v-on="on" v-bind="attrs" :rules="[notNullNorEmpty]"
                            label="Date de fin"
                            :value="getFormattedDate(periodeModel.date_end)"></v-text-field>
            </template>
            <v-card>
              <v-date-picker @change="calculateNombrePeriodes" v-model="periodeModel.date_end"></v-date-picker>
              <v-card-actions>
                <v-btn text color="primary" @click="date_end_dialog = false">Fermer</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-row no-gutters justify="end">
            <v-switch class="mr-4" v-model="showOldInput" label="Entrer le coût total"></v-switch>
          </v-row>
          <v-row v-if="!showOldInput">
            <v-col cols="6">
              <v-text-field v-model="periodes" :rules="[value => !isNaN(value)]" type="number"
                            label="Nombre de périodes"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="cost_per_periode" :rules="[value => !isNaN(value)]" type="number" step="0.01"
                            label="Coût par période"></v-text-field>
            </v-col>
            <v-col class="my-0" cols="12">
              <span class="body-1">Coût total de la période du contrat : {{ cost_per_periode * periodes }} €</span>
            </v-col>
          </v-row>
          <v-text-field v-else :rules="[notNullNorEmpty]" label="Coût" v-model="cost" type="number"></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" v-if="operation === 'create' || this.operation === 'reupdate'" text
               @click="createPeriode">
          Créer le contrat
        </v-btn>
        <v-btn color="primary" v-if="operation === 'update' || this.operation === 'reupdate'" text
               @click="updatePeriode">
          Mettre à jour
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";
import dayjs from "dayjs";

export default {
  name: "PeriodeForm",
  mixins: [DateUtilMixin, DrawerMixin, ValidationRulesMixin],
  props: {
    periode: Object,
    operation: String,
    contract_id: Number,
  },
  data() {
    return {
      periodeModel: this.operation === 'update' ? JSON.parse(JSON.stringify(this.periode)) : {
        "date_start": null,
        "date_end": null,
        "cost": null,
        "contract": null,
      },
      date_start_dialog: false,
      date_end_dialog: false,
      showOldInput: this.operation === 'update',
      periodes: null,
      cost_per_periode: null,
      cost: this.operation === 'update' ? this.periode.cost : null,
    }
  },
  methods: {
    async createPeriode() {
      this.periodeModel.cost = this.cost;
      this.periodeModel.contract = this.contract_id;
      await this.$store.dispatch("periodes/postPeriode", this.periodeModel);
      this.$root.$emit("reloadPeriodes");
      this.closeDrawerDialog();
    },
    async updatePeriode() {
      this.periodeModel.cost = this.cost;
      this.periodeModel.contract = this.contract_id;
      await this.$store.dispatch("periodes/patchPeriode", this.periodeModel);
      this.$root.$emit("reloadPeriodes");
      this.closeDrawerDialog();
    },
    calculateNombrePeriodes() {
      if(this.periodeModel.date_start && this.periodeModel.date_end) {
        let start = dayjs(this.periodeModel.date_start);
        let end = dayjs(this.periodeModel.date_end);
        this.periodes = end.diff(start, "month");
      }
    }
  }
}
</script>

<style scoped>

</style>