<template>

  <v-row justify="center">
    <v-col cols="12">
      <v-row justify="end">
        <v-btn class="mr-4" color="primary" @click="renewContract">Renouveller le contrat</v-btn>
        <v-btn class="mr-4" color="primary" @click="openPeriodeCreationDialog">Créer une nouvelle période</v-btn>
      </v-row>
    </v-col>
    <v-expansion-panels accordion>
      <v-expansion-panel
        v-for="(periode,i) in periodes"
        :key="i"
      >
        <v-expansion-panel-header>
          <h3 class="primary--text">Période de {{ getReadableDate(periode.date_start) }} à {{ getReadableDate(periode.date_end) }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template>
              <thead>
              <tr>
                <th>Référence</th>
                <th>Date de gestion</th>
                <th>Réalisé</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="entry in periode.entries" :key="entry.id">
                <td>{{ entry.reference }}</td>
                <td>{{ getFormattedDate(entry.date_gestion)}}</td>
                <td>{{ entry.realise}} €</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row>
            <v-btn @click="deletePeriode(periode)" color="red" class="white--text"><v-icon>mdi-trash-can</v-icon> Supprimer</v-btn>
            <v-btn @click="openPeriodeUpdateDialog(periode)" color="primary" class="white--text"><v-icon>mdi-pencil</v-icon>Modifier</v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import PeriodeForm from "@/views/PeriodeForm";

export default {
  name: 'Periodes',
  props: {
    contract: Object,
  },
  mixins: [DateUtilMixin, DrawerMixin],
  mounted: async function () {
    this.periodes = await this.$store.dispatch("periodes/fetchPeriodesByContract", this.contract);
    let that = this;
    this.$root.$on("reloadPeriodes", async function () {
      that.periodes = await that.$store.dispatch("periodes/fetchPeriodesByContract", that.contract);
      let count_entries = that.periodes?.map((periode) => periode.entries.length);
      let hasPeriodeWithNoEntries = count_entries.indexOf(0) !== -1;
      if (hasPeriodeWithNoEntries) {
        setTimeout(async () => {
          return that.periodes = await that.$store.dispatch("periodes/fetchPeriodesByContract", that.contract);
        }, 3000
      )
      }
    });
  },
  data() {
    return {
      periodes: [],
    }
  },
  methods: {
    openPeriodeCreationDialog() {
      this.openDrawerDialog(PeriodeForm, {periode: null, operation: "create", contract_id: this.contract.id, key: 0});
    },
    openPeriodeUpdateDialog(periode) {
      this.openDrawerDialog(PeriodeForm, {periode: periode, operation: "update", contract_id: this.contract.id, key: periode?.id});
    },
    async renewContract() {
      let result = await this.$store.dispatch("contracts/renewContract", this.contract);
      if(result === "success") {
        this.$root.$emit("reloadPeriodes");
      }
    },
    async deletePeriode(periode) {
      let result = await this.$store.dispatch("periodes/deletePeriode", periode);
      if(result === "success") {
        this.$store.dispatch("messages/announceSuccess", "Période supprimée");
        this.$root.$emit("reloadPeriodes");
      }
    }
  }
}
</script>
